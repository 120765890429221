import CustomIcon from '../Icons';
import PropTypes from 'prop-types';
import React from 'react';
import style from './styles';
import { withStyles } from '@material-ui/core';

const QuotedText = ({ ...props }) => {
	const {
		classes,
		textToRender,
	} = props;

	return (
		<div>
			<CustomIcon className={classes.icons} iconName="leftQuote" />
			<span className={classes.subtitle}> {textToRender} </span>
			<CustomIcon className={classes.icons} iconName="rightQuote" />
		</div>
	);
};

QuotedText.propTypes = {
	classes: PropTypes.object.isRequired,
	textToRender: PropTypes.string,
};

export default withStyles(style)(QuotedText);
