export default () => ({
	bottom: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '4em',
	},
	content: {
		color: '#444',
		fontSize: '17.26px',
		fontWeight: '300',
		lineHeight: '1.65em',
		textAlign: 'justify',
		textJustify: 'inter-word',
	},
	header: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '150px',
	},
	icons: {
		fontSize: 'large',
		height: '12px',
		margin: '0 6px 4px 6px',
		width: '12px',
	},
	subtitle: {
		fontSize: '25px',
		fontWeight: '300',
	},
});
