export default () => ({
	content: {
		color: '#888',
		fontSize: '18px',
		fontWeight: '300',
		lineHeight: '1.65em',
	},
	header: {
		color: '#ffffff',
		fontSize: '21.6px',
		fontWeight: '300',
		lineHeight: '1.5em',
		margin: '0 0 1em 0',
	},
});
