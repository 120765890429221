const drawerWidth = 260;
const transition = {
	transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const navbarStyle = (theme) => ({
	absolute: {
		position: 'absolute',
		zIndex: '1100',
	},
	appBar: {
		alignItems: 'center',
		backgroundColor: 'transparent',
		border: '0',
		borderRadius: '3px',
		boxShadow: 'none',
		color: '#fff',
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'flex-start',
		marginBottom: '20px',
		padding: '0.625rem 0',
		position: 'relative',
		transition: 'all 150ms ease 0s',
		width: '100%',
		zIndex: 'unset',
	},
	appResponsive: {
		margin: '20px 10px',
	},
	brandContainer: {
		display: 'flex',
		flex: '1',
	},
	brandImage: {
		'&:hover,&:focus': {
			background: 'transparent',
			color: 'inherit',
		},
		color: 'inherit',
		cursor: 'pointer',
		height: '75px',
		marginTop: '2px',
		width: '75px',
	},
	brandTitle: {
		'&:hover,&:focus': {
			background: 'transparent',
			color: 'inherit',
		},
		borderRadius: '3px',
		color: 'inherit',
		fontSize: '18px',
		letterSpacing: 'unset',
		lineHeight: '30px',
		paddingLeft: '0',
		textTransform: 'none',
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		flex: '1',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		minHeight: '50px',
		paddingLeft: '2.5em',
	},
	danger: {
		backgroundColor: theme.palette.error.main,
		boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)',
		color: '#FFFFFF',
	},
	dark: {
		backgroundColor: '#212121 !important',
		boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)',
		color: '#FFFFFF',
	},
	drawerPaper: {
		backgroundColor: theme.palette.colors.grey[900],
		border: 'none',
		borderTop: 'none',
		bottom: '0',
		display: 'block',
		height: '100vh',
		left: 'auto',
		overflowY: 'visible',
		paddingLeft: '0',
		...transition,
		paddingRight: '0px',
		position: 'fixed',
		right: '0',
		textAlign: 'left',
		top: '0',
		transitionDuration: '.2s, .2s, .35s',
		transitionProperty: 'top, bottom, width',
		transitionTimingFunction: 'linear, linear, ease',
		visibility: 'visible',
		width: drawerWidth,
	},
	fixed: {
		position: 'fixed',
		zIndex: '1100',
	},
	flex: {
		flex: 1,
	},
	info: {
		backgroundColor: theme.palette.info.main,
		boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)',
		color: '#FFFFFF',
	},
	navbarPrimary: {
		backgroundColor: '#000000',
		boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px ${theme.palette.colors.grey[700]}`,
		color: '#FFFFFF',
	},
	primary: {
		backgroundColor: '#000000',
		boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px ${theme.palette.colors.grey[700]}`,
		color: '#FFFFFF',
	},
	primaryLight: {
		backgroundColor: theme.palette.primary.light,
		boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(66, 165, 245, 1)',
		color: '#FFFFFF',
	},
	success: {
		backgroundColor: theme.palette.success.main,
		boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)',
		color: '#FFFFFF',
	},
	title: {
		'&:hover,&:focus': {
			background: 'transparent',
			color: 'inherit',
		},
		borderRadius: '3px',
		color: 'inherit',
		fontSize: '18px',
		letterSpacing: 'unset',
		lineHeight: '30px',
		padding: '8px 16px',
		textTransform: 'none',
	},
	transparent: {
		backgroundColor: 'transparent !important',
		boxShadow: 'none',
		color: '#FFFFFF',
	},
	warning: {
		backgroundColor: theme.palette.warning.main,
		boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)',
		color: '#FFFFFF',
	},
	white: {
		backgroundColor: '#fff !important',
		border: '0',
		boxShadow: '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
		color: '#555',
		marginBottom: '20px',
		padding: '0.625rem 0',
	},
});

export default navbarStyle;
