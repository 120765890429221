const navBarMenuStyle = (theme) => ({
	icons: {
		fontSize: 'large',
		height: '24px',
		marginBottom: '4px',
		marginRight: '6px',
		width: '24px',
	},
	list: {
		fontSize: '14px',
		listStyle: 'none',
		margin: 0,
		paddingBottom: '0',
		paddingLeft: '0',
		paddingTop: '0',
	},
	listItem: {
		[theme.breakpoints.down('sm')]: {
			'&:after': {
				backgroundColor: '#e5e5e5',
				content: '""',
				display: 'block',
				height: '1px',
				marginLeft: '15px',
				width: 'calc(100% - 30px)',
			},
			width: '100%',
		},
		display: 'block',
		float: 'left',
		margin: '0',
		padding: '0',
		position: 'relative',
		width: 'auto',
	},
	navLink: {
		[theme.breakpoints.down('sm')]: {
			'& > span:first-child': {
				justifyContent: 'flex-start',
			},
			marginBottom: '8px',
			marginLeft: '15px',
			marginTop: '8px',
			textAlign: 'left',
			width: 'calc(100% - 30px)',
		},
		'&:hover': {
			color: '#D68B1B',
		},
		borderRadius: '3px',
		color: '#ffffff',
		display: 'inline-flex',
		fontSize: '18.5px',
		fontWeight: '300',
		margin: '0px',
		padding: '0.9375rem',
		position: 'relative',
		textDecoration: 'none !important',
	},
});

export default navBarMenuStyle;
