import {
	AppBar,
	Drawer,
	Hidden,
	IconButton,
	Toolbar,
	withStyles,
} from '@material-ui/core';
import brandImage from '../../../assets/images/logo.png';
import clsx from 'clsx';
import Menu from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import style from './styles';

const Navbar = ({ ...props }) => {
	const {
		absolute,
		changeColorOnScroll,
		classes,
		color,
		fixed,
		leftLinks,
		rightLinks,
	} = props;

	const [mobileOpen, setMobileOpen] = React.useState(false);

	const headerColorChange = () => {
		const windowsScrollTop = window.pageYOffset;
		if (windowsScrollTop > changeColorOnScroll.height) {
			document.body
				.getElementsByTagName('header')[0]
				.classList.remove(classes[color]);
			document.body
				.getElementsByTagName('header')[0]
				.classList.add(classes[changeColorOnScroll.color]);
		} else {
			document.body
				.getElementsByTagName('header')[0]
				.classList.add(classes[color]);
			document.body
				.getElementsByTagName('header')[0]
				.classList.remove(classes[changeColorOnScroll.color]);
		}
	};

	React.useEffect(() => {
		if (props.changeColorOnScroll) {
			window.addEventListener('scroll', headerColorChange);
		}
		return function cleanup() {
			if (props.changeColorOnScroll) {
				window.removeEventListener('scroll', headerColorChange);
			}
		};
	});

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const appBarClasses = clsx({
		[classes.appBar]: true,
		[classes[color]]: color,
		[classes.absolute]: absolute,
		[classes.fixed]: fixed,
	});

	const brandComponent = (
		<div className={classes.brandContainer}>
			<img alt="brand" className={classes.brandImage} src={brandImage} />
		</div>
	);

	return (
		<AppBar className={appBarClasses}>
			<Toolbar className={classes.container}>
				{leftLinks !== undefined ? brandComponent : null}
				<div className={classes.flex}>
					{leftLinks !== undefined ? (
						<Hidden implementation="css" smDown>
							{leftLinks}
						</Hidden>
					) : (
						brandComponent
					)}
				</div>
				<Hidden implementation="css" smDown>
					{rightLinks}
				</Hidden>
				<Hidden mdUp>
					<IconButton
						aria-label="open drawer"
						color="inherit"
						onClick={handleDrawerToggle}
					>
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
			<Hidden implementation="js" mdUp>
				<Drawer
					anchor="right"
					classes={{
						paper: classes.drawerPaper,
					}}
					onClose={handleDrawerToggle}
					open={mobileOpen}
					variant="temporary"
				>
					<div className={classes.appResponsive}>
						{leftLinks}
						{rightLinks}
					</div>
				</Drawer>
			</Hidden>
		</AppBar>
	);
};

Navbar.defaultProp = {
	color: 'primary',
};

Navbar.propTypes = {
	absolute: PropTypes.bool,
	brand: PropTypes.string,
	changeColorOnScroll: PropTypes.shape({
		color: PropTypes.oneOf([
			'dark',
			'primary',
			'primaryLight',
			'transparent',
			'white',
		]).isRequired,
		height: PropTypes.number.isRequired,
	}),
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf([
		'dark',
		'navbarPrimary',
		'primaryLight',
		'transparent',
		'white',
	]),
	fixed: PropTypes.bool,
	leftLinks: PropTypes.node,
	rightLinks: PropTypes.node,
};

export default withStyles(style)(Navbar);
