import PropTypes from 'prop-types';
import React from 'react';
import style from './styles';
import { withStyles } from '@material-ui/core';

const Card = ({ ...props }) => {
	const {
		children, classes, content, header,
	} = props;
	return (
		<>
			<div className={classes.header}>{header}</div>
			{children && (<>{ children }</>)}
			{content && (<div className={classes.content}>{content}</div>)}
		</>
	);
};

Card.propTypes = {
	children: PropTypes.object,
	classes: PropTypes.object.isRequired,
	content: PropTypes.string,
	header: PropTypes.string,
};

export default withStyles(style)(Card);
