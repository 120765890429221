export default () => ({
	footer: {
		background: '#000000',
		color: '#888',
		fontSize: '14px',
		padding: '18px 0em 18px',
		textAlign: 'center',
	},
	footerContent: {
		fontSize: '16px',
		fontWeight: '200',
		margin: '0',
		padding: '35px 0 5px 0',
		textAlign: 'center',
	},
});
