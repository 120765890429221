import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const theme = createMuiTheme({
	palette: {
		action: {
			active: 'rgba(0, 0, 0, 0.54)',
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)',
			hover: 'rgba(0, 0, 0, 0.08)',
			hoverOpacity: 0.08,
			selected: 'rgba(0, 0, 0, 0.14)',
		},
		background: {
			default: '#fafafa',
			paper: '#fff',
		},
		colors: {
			grey,
		},
		common: {
			black: '#000',
			white: '#fff',
		},
		contrastThreshold: 3,
		divider: 'rgba(0, 0, 0, 0.12)',
		error: {
			contrastText: '#fff',
			dark: '#d32f2f',
			light: '#e57373',
			main: '#f44336',
		},
		grey: {
			50: '#fafafa',
			100: '#f5f5f5',
			200: '#eeeeee',
			300: '#e0e0e0',
			400: '#bdbdbd',
			500: '#9e9e9e',
			600: '#757575',
			700: '#616161',
			800: '#424242',
			900: '#212121',
			A100: '#d5d5d5',
			A200: '#aaaaaa',
			A400: '#303030',
			A700: '#616161',
		},
		primary: {
			contrastText: '#fff',
			dark: 'rgb(38, 49, 55)',
			light: 'rgb(95, 107, 114)',
			main: '#37474F',
		},
		secondary: {
			contrastText: '#fff',
			dark: 'rgb(178, 60, 23)',
			light: 'rgb(255, 120, 78)',
			main: '#FF5722',
		},
		text: {
			disabled: 'rgba(0, 0, 0, 0.38)',
			hint: 'rgba(0, 0, 0, 0.38)',
			primary: 'rgba(0, 0, 0, 0.87)',
			secondary: 'rgba(0, 0, 0, 0.54)',
		},
	},
	typography: {
		fontFamily: [
			'"Lato"',
			'Open Sans',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
		].join(','),
		// Tell Material-UI what's the font-size on the html element is.
		htmlFontSize: 10, // font-size: 62.5%; /* 62.5% of 16px = 10px */
	},
});

export default responsiveFontSizes(theme);
