/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconPaths = ({
	aboutUs: ['M7.413,12A4.321,4.321,0,0,0,4.1,13.714H2.425A2.151,2.151,0,0,1,0,11.585C0,10.5-.038,6.857,1.55,6.857,1.812,6.857,3.112,8,4.8,8a4.707,4.707,0,0,0,1.662-.308,6.993,6.993,0,0,0-.062.884A6.353,6.353,0,0,0,7.412,12ZM20.8,20.531A3.173,3.173,0,0,1,17.462,24H6.538A3.173,3.173,0,0,1,3.2,20.531c0-3.027.662-7.674,4.325-7.674.425,0,1.975,1.862,4.475,1.862s4.05-1.862,4.475-1.862c3.662,0,4.325,4.647,4.325,7.674ZM8,3.429A3.327,3.327,0,0,1,4.8,6.857,3.327,3.327,0,0,1,1.6,3.429,3.327,3.327,0,0,1,4.8,0,3.327,3.327,0,0,1,8,3.429Zm8.8,5.143A4.982,4.982,0,0,1,12,13.714,4.982,4.982,0,0,1,7.2,8.571,4.982,4.982,0,0,1,12,3.429,4.982,4.982,0,0,1,16.8,8.571ZM24,11.585a2.152,2.152,0,0,1-2.425,2.129H19.9A4.321,4.321,0,0,0,16.587,12,6.359,6.359,0,0,0,17.6,8.571a6.971,6.971,0,0,0-.062-.884A4.707,4.707,0,0,0,19.2,8c1.687,0,2.987-1.138,3.25-1.138,1.587,0,1.55,3.643,1.55,4.728ZM22.4,3.429a3.327,3.327,0,0,1-3.2,3.429A3.327,3.327,0,0,1,16,3.429,3.327,3.327,0,0,1,19.2,0,3.327,3.327,0,0,1,22.4,3.429Z'],
	home: ['M22 15.5v7.5c0 0.547-0.453 1-1 1h-6v-6h-4v6h-6c-0.547 0-1-0.453-1-1v-7.5c0-0.031 0.016-0.063 0.016-0.094l8.984-7.406 8.984 7.406c0.016 0.031 0.016 0.063 0.016 0.094zM25.484 14.422l-0.969 1.156c-0.078 0.094-0.203 0.156-0.328 0.172h-0.047c-0.125 0-0.234-0.031-0.328-0.109l-10.813-9.016-10.813 9.016c-0.109 0.078-0.234 0.125-0.375 0.109-0.125-0.016-0.25-0.078-0.328-0.172l-0.969-1.156c-0.172-0.203-0.141-0.531 0.063-0.703l11.234-9.359c0.656-0.547 1.719-0.547 2.375 0l3.813 3.187v-3.047c0-0.281 0.219-0.5 0.5-0.5h3c0.281 0 0.5 0.219 0.5 0.5v6.375l3.422 2.844c0.203 0.172 0.234 0.5 0.063 0.703z'],
	leftQuote: ['M11.077,16.182v6.545A3.054,3.054,0,0,1,8.308,26H2.769A3.054,3.054,0,0,1,0,22.727v-12C0,5.921,3.318,2,7.385,2h.923a1.025,1.025,0,0,1,.923,1.091V5.273a1.025,1.025,0,0,1-.923,1.091H7.385c-2.034,0-3.692,1.96-3.692,4.364v.545a1.527,1.527,0,0,0,1.385,1.636H8.308A3.054,3.054,0,0,1,11.077,16.182Zm12.923,0v6.545A3.054,3.054,0,0,1,21.231,26H15.692a3.054,3.054,0,0,1-2.769-3.273v-12C12.923,5.921,16.241,2,20.308,2h.923a1.025,1.025,0,0,1,.923,1.091V5.273a1.025,1.025,0,0,1-.923,1.091h-.923c-2.034,0-3.692,1.96-3.692,4.364v.545A1.527,1.527,0,0,0,18,12.909h3.231A3.054,3.054,0,0,1,24,16.182Z'],
	portfolio: ['M18.479,13.062,18.667,18c.083,2.2-2.989,4-6.667,4s-6.75-1.8-6.667-4l.187-4.937L11.5,15.89a1.2,1.2,0,0,0,1,0ZM24,8a.553.553,0,0,1-.229.484L12.1,13.984a.346.346,0,0,1-.208,0L5.1,10.765c-.594.7-1.011,2.422-1.094,4.531A2.257,2.257,0,0,1,4.667,17a2.362,2.362,0,0,1-.6,1.672l.6,6.766a.7.7,0,0,1-.083.391.3.3,0,0,1-.25.172h-2a.3.3,0,0,1-.25-.172A.694.694,0,0,1,2,25.437l.6-6.766A2.362,2.362,0,0,1,2,17a2.313,2.313,0,0,1,.677-1.734A13.974,13.974,0,0,1,3.7,10.109L.229,8.484a.625.625,0,0,1,0-.968L11.9,2.016a.346.346,0,0,1,.208,0l11.667,5.5A.555.555,0,0,1,24,8Z'],
	register: ['M 16.875 0 L 1.875 0 C 0.84375 0 0 0.84375 0 1.875 L 0 18.125 C 0 19.15625 0.84375 20 1.875 20 L 16.875 20 C 17.90625 20 18.75 19.15625 18.75 18.125 L 18.75 1.875 C 18.75 0.84375 17.90625 0 16.875 0 Z M 16.25 17.5 L 2.5 17.5 L 2.5 2.5 L 16.25 2.5 Z M 5 11.25 L 13.75 11.25 L 13.75 12.5 L 5 12.5 Z M 5 13.75 L 13.75 13.75 L 13.75 15 L 5 15 Z M 6.25 5.625 C 6.25 4.589844 7.089844 3.75 8.125 3.75 C 9.160156 3.75 10 4.589844 10 5.625 C 10 6.660156 9.160156 7.5 8.125 7.5 C 7.089844 7.5 6.25 6.660156 6.25 5.625 Z M 9.375 7.5 L 6.875 7.5 C 5.84375 7.5 5 8.0625 5 8.75 L 5 10 L 11.25 10 L 11.25 8.75 C 11.25 8.0625 10.40625 7.5 9.375 7.5 Z M 9.375 7.5', 'M 18.75 0.625 L 13.125 6.25 L 11.25 4.375 L 10 5.625 L 13.125 8.75 L 18.125 1.875 Z M 18.75 0.625'],
	rightQuote: ['M11.077,5.273v12C11.077,22.079,7.759,26,3.692,26H2.769a1.025,1.025,0,0,1-.923-1.091V22.727a1.025,1.025,0,0,1,.923-1.091h.923c2.034,0,3.692-1.96,3.692-4.364v-.545A1.527,1.527,0,0,0,6,15.091H2.769A3.054,3.054,0,0,1,0,11.818V5.273A3.054,3.054,0,0,1,2.769,2H8.308A3.054,3.054,0,0,1,11.077,5.273ZM24,5.273v12C24,22.079,20.682,26,16.615,26h-.923a1.025,1.025,0,0,1-.923-1.091V22.727a1.025,1.025,0,0,1,.923-1.091h.923c2.034,0,3.692-1.96,3.692-4.364v-.545a1.527,1.527,0,0,0-1.385-1.636H15.692a3.054,3.054,0,0,1-2.769-3.273V5.273A3.054,3.054,0,0,1,15.692,2h5.538A3.054,3.054,0,0,1,24,5.273Z'],
	user: ['M20 21.859c0 2.281-1.5 4.141-3.328 4.141h-13.344c-1.828 0-3.328-1.859-3.328-4.141 0-4.109 1.016-8.859 5.109-8.859 1.266 1.234 2.984 2 4.891 2s3.625-0.766 4.891-2c4.094 0 5.109 4.75 5.109 8.859zM16 8c0 3.313-2.688 6-6 6s-6-2.688-6-6 2.688-6 6-6 6 2.688 6 6z'],
});

const CustomIcon = ({ ...props }) => {
	const { iconName, ...rest } = props;

	return (
		<SvgIcon {...rest}>
			{IconPaths[iconName].map((path, index) => (<path d={path} key={index} />))}
		</SvgIcon>
	);
};

CustomIcon.propTypes = {
	iconName: PropTypes.string,
};

export default CustomIcon;
