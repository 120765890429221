/* eslint-disable max-len */
import {
	Container,
	Grid,
	withStyles,
} from '@material-ui/core';
import brandImage from '../../../assets/images/cadju_small.png';
import PropTypes from 'prop-types';
import { QuotedText } from '../../components';
import React from 'react';
import style from './styles';

const About = ({ ...props }) => {
	const { classes } = props;
	return (
		<>
			<div className={classes.header}>
				<div>
					<div>
						<img alt="" src={brandImage} />
					</div>
					<QuotedText textToRender=" A Boutique IT Consultancy " />
				</div>
			</div>
			<div>
				<Container component="main" maxWidth="lg">
					<Grid container spacing={2}>
						<Grid className={classes.servicesContainer} item>
							<p className={classes.content}>
								We are a unique software consultancy company based in the heart of Canary Wharf in the
								great city of London. We are surrounded by major financial institutions and professional
								services firms. We have a combined experience of more than 35 years in the field of
								Enterprise Architecture, Software Design, Software Development, Business Analysis,
								Project Management, Programme Management, IT Strategy, IT Outsourcing &amp; Offshoring,
								Process Improvement, Automation, International Tax and Managing IT Development Teams.
							</p>
							<p className={classes.content}>
								Prior to forming the company, the team had a varied and rich experiences of working for
								international conglomerates such as Accenture, Deloitte, Arthur Andersen, KPMG, Nortel and UBS
								to name a few. Our main area of industry focus are on Banking, Tax (local and foreign jurisdictions),
								Accounting, Global Mobility, People Services, Pensions and HR Technology. Through a professional services
								firm we have extensive experiences of working with multinational companies in the domain of Pharmaceuticals,
								Oil &amp; Gas, Banking, Telecommunications, Retail and Engineering.
							</p>
							<p className={classes.content}>
								At Cadju we have a ‘can-do &amp; will-do’ attitude towards all IT related challenges. No problem is too small
								or too big! Founder and Managing Director, Bali Balraj, simply put it
								<i> &quot;we thrive to work with our clients
								to understand their transformation needs &amp; challenges and break each problem into bitesize, analyse, design,
								solve and support! Cadju’s success is not only depended on our clients’ success but also our dedicated employees
								and their work ethics. Let’s all learn, innovate, build, transform and enjoy the digital journey together!&quot;
								</i>
							</p>
						</Grid>
					</Grid>
				</Container>
			</div>
			<div className={classes.bottom}>
				<QuotedText textToRender=" We look forward to your custom " />
			</div>
		</>
	);
};

About.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(style)(About);
