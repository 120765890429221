import {
	Link,
	withStyles,
} from '@material-ui/core';
import CustomIcon from '../Icons';
import PropTypes from 'prop-types';
import React from 'react';
import style from './styles';

const IconLink = ({ ...props }) => {
	const {
		classes,
		iconName,
		linkText,
		pathToRedirect,
	} = props;

	return (
		<Link
			className={classes.navLink}
			color="secondary"
			href={pathToRedirect}
		>
			<CustomIcon className={classes.icon} iconName={iconName} />
			{linkText}
		</Link>
	);
};

IconLink.propTypes = {
	classes: PropTypes.object.isRequired,
	iconName: PropTypes.string,
	linkText: PropTypes.string,
	pathToRedirect: PropTypes.string,
};

export default withStyles(style)(IconLink);
