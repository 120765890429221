import {
	Card,
	List,
} from '../../components';
import {
	Container,
	Grid,
	withStyles,
} from '@material-ui/core';
import logoImage from '../../../assets/images/logo.png';
import PropTypes from 'prop-types';
import React from 'react';
import style from './styles';

const services = [
	'IT Strategy & Transformation',
	'IT Outsourcing & Offshoring',
	'Enterprise Architecture',
	'Software Design & Development',
	'Process Automation',
];

const Home = ({ ...props }) => {
	const { classes } = props;
	return (
		<>
			<div className={classes.banner}>
				<div className={classes.bannerHeader}>Cadju Consulting</div>
				<div className={classes.bannerSubtitle}>Let us innovate and transform technology together</div>
				<ul className={classes.actions}>
					<li className={classes.actionChild}>
						<a className={classes.anchorButton} href="/about">
							<span><img alt="" height="20" src={logoImage} width="20" /></span>
					&nbsp;&nbsp;Learn More
						</a>
					</li>
				</ul>
			</div>
			<div className={classes.bottomContainer}>
				<Container component="main" maxWidth="lg">
					<Grid container spacing={2}>
						<Grid item sm={4} xs={12}>
							<Card
								content="Cadju is hiring mid-level and experienced .NET developers."
								header="Careers"
							/>
						</Grid>
						<Grid className={classes.servicesContainer} item sm={4} xs={12}>
							<Card header="Services">
								<List
									borderAtTop
									items={services}
								/>
							</Card>
						</Grid>
						<Grid item sm={4} xs={12}>
							<div className={classes.bottomHeader}>Contact Us</div>
							<div className={classes.addressContainer}>
								<div className={classes.addressLeftHeader}>Address</div>
								<div className={classes.addressContent}>
									18th Floor<br />
									40 Bank Street<br />
									Canary Wharf<br />
									London, E14 5NR
								</div>
							</div>
							<div className={classes.horizontalLine} />
							<div className={classes.addressContainer}>
								<div className={classes.addressLeftHeader}>Mail</div>
								<div className={classes.addressContent}>
									<a className={classes.anchorTag} href="mailto:support@cadju.com">
										support@cadju.com
									</a>
								</div>
							</div>
						</Grid>
					</Grid>
				</Container>
			</div>
		</>
	);
};

Home.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Home);
