import { Footer, Navbar, NavbarMenu } from '../components';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const useStyles = makeStyles(styles);

const MainLayout = ({ children }) => {
	const classes = useStyles();
	return (
		<div className={classes.site}>
			<div className={classes.siteContent}>
				<div className={classes.appHeader}>
					<Navbar
						brand="Cadju"
						changeColorOnScroll={{
							color: 'primary',
							height: 400,
						}}
						color={children.props.location.pathname === '/' ? 'transparent' : 'navbarPrimary'}
						fixed
						rightLinks={<NavbarMenu />}
					/>
				</div>
				<div className={classes.main}>
					{children}
				</div>
			</div>
			<Footer />
		</div>
	);
};

MainLayout.propTypes = {
	children: PropTypes.object,
};

export default MainLayout;
