import bannerImage from '../../../assets/images/banner.jpg';
import overlayImage from '../../../assets/images/overlay.png';

const bottomHeaderFont = {
	color: '#ffffff',
	fontSize: '21.6px',
	fontWeight: '300',
	lineHeight: '1.5em',
	margin: '0 0 1em 0',
};

const bottomContentFont = {
	color: '#888',
	fontSize: '18px',
	fontWeight: '300',
	lineHeight: '1.65em',
};

export default () => ({
	actionChild: {
		display: 'inline-block',
		padding: '0em',
		verticalAlign: 'middle',
	},
	actions: {
		cursor: 'default',
		listStyle: 'none',
		margin: '3em 0 0 0',
		paddingLeft: '0',
	},
	addressContainer: {
		display: 'flex',
		paddingLeft: '20px !important',
		paddingTop: '12px',
	},
	addressContent: {
		...bottomContentFont,
	},
	addressHeader: {
		...bottomHeaderFont,
	},
	addressLeftHeader: {
		...bottomHeaderFont,
		width: '30%',
	},
	anchorButton: {
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.2)',
		},
		backgroundColor: 'transparent',
		border: '1px solid #ffffff',
		borderRadius: '4px',
		color: '#ffffff !important',
		cursor: 'pointer',
		display: 'inline-block',
		fontSize: '21.6px',
		fontWeight: '300',
		height: '2.85em',
		lineHeight: '2.8em',
		padding: '0 2em',
		textAlign: 'center',
		textDecoration: 'none',
		webkitAppearance: 'none',
		whiteSpace: 'nowrap',
	},
	anchorTag: {
		'&:hover': {
			color: '#ffffff',
		},
		color: '#888',
		visited: 'none',
	},
	banner: {
		background: `url(${overlayImage}), url(${bannerImage})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		color: '#ffffff',
		padding: '12em 0em 10em',
		textAlign: 'center',
	},
	bannerHeader: {
		color: '#ffffff',
		fontSize: '56px',
		fontWeight: '300',
		lineHeight: '1em',
		margin: '0 0 0.5em 0',
		padding: '0',
	},
	bannerSubtitle: {
		fontSize: '24px',
		fontWeight: '300',
		margin: '1.5em 0 0 0',
	},
	bottomContainer: {
		background: '#000000',
		color: '#888',
		padding: '1em 0em 1em',
	},
	bottomContent: {
		...bottomContentFont,
	},
	bottomHeader: {
		...bottomHeaderFont,
	},
	bottomRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
	},
	horizontalLine: {
		borderTop: 'solid 1px rgba(144, 144, 144, 0.25)',
		lineHeight: '1.75em',
		margin: '1em 0px 0px',
	},
	li: {
		borderTop: 'solid 1px rgba(144, 144, 144, 0.25)',
		padding: '0.8em 0',
	},
	liFirstChild: {
		borderTop: '0',
		padding: '0.8em 0',
	},
	servicesContainer: {
		paddingRight: '20px !important',
	},
	ul: {
		listStyle: 'none',
		paddingLeft: '0',
		...bottomContentFont,
	},
});
