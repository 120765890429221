export default () => ({
	icons: {
		fontSize: 'large',
		height: '12px',
		margin: '0 6px 4px 6px',
		width: '12px',
	},
	subtitle: {
		fontSize: '25px',
		fontWeight: '300',
	},
});
