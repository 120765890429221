/* eslint-disable max-len */
import {
	Container,
	Grid,
	withStyles,
} from '@material-ui/core';
import {
	List,
	QuotedText,
} from '../../components';
import PropTypes from 'prop-types';
import React from 'react';
import style from './styles';

const services = [
	'UK VAT & Customs Software',
	'P11D Software',
	'Corporate Tax Systems',
	'Intelligent Organiser (Global tax data collector)',
	'Global Immigration Software',
	'Compensation Collector & Analyser',
	'Various International Assignment Management Systems',
	'Employee Benefits System',
	'Electronic Payslip System',
	'Document Management System',
	'Employee Portals',
	'Payroll APIs & Payroll Adjustment Module',
	'Global Business Traveller',
	'UK Residency Tool',
	'US State to State Tax Tool',
	'Pensions Tax Analyser',
	'Project Managing Data Centre Moves',
	'Azure Cloud Migrations',
];
const services1 = services.splice(6);
const services2 = services1.splice(6);

const Portfolio = ({ ...props }) => {
	const { classes } = props;
	return (
		<>
			<div className={classes.header}>
				<div>
					<div className={classes.title}>
						<div>Our Portfolio</div>
					</div>
					<QuotedText textToRender=" Let&apos;s see what we have created " />
				</div>
			</div>
			<Container component="main" maxWidth="lg">
				<Grid container spacing={2}>
					<Grid item sm={4} xs={12}>
						<div className={classes.content}>
							<List color="darkColor" items={services} />
						</div>
					</Grid>
					<Grid className={classes.servicesContainer} item sm={4} xs={12}>
						<div className={classes.content}>
							<List items={services1} />
						</div>
					</Grid>
					<Grid item sm={4} xs={12}>
						<div className={classes.content}>
							<List items={services2} />
						</div>
					</Grid>
				</Grid>
			</Container>
			<div className={classes.bottom}>
				<QuotedText textToRender=" We look forward to your custom " />
			</div>
		</>
	);
};

Portfolio.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Portfolio);
