import {
	About,
	Home,
	Portfolio,
} from '../ui/containers';
import {
	Redirect,
	BrowserRouter as Router,
	Switch,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { MainLayout } from '../ui/layouts';
import PublicRoute from './PublicRoute';
import React from 'react';

const hist = createBrowserHistory();

const router = () => (
	<Router history={hist}>
		<Switch>
			<PublicRoute component={Home} exact layout={MainLayout} path="/" />
			<PublicRoute component={About} layout={MainLayout} path="/about" />
			<PublicRoute component={Portfolio} layout={MainLayout} path="/portfolio" />
			<Redirect from="/home" to="/" />
			<Redirect from="/" to="/" />
		</Switch>
	</Router>
);

router.displayName = 'Router';
export default router;
