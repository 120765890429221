export default () => ({
	body: {
		display: 'flex',
		justifyContent: 'center',
	},
	bottom: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '4em',
	},
	content: {
		color: '#444 !important',
		fontSize: '17.26px',
		fontWeight: '300',
		lineHeight: '1.65em',
		padding: '2em',
		textAlign: 'justify',
	},
	header: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '125px',
	},
	icons: {
		fontSize: 'large',
		height: '12px',
		margin: '0 6px 4px 6px',
		width: '12px',
	},
	subtitle: {
		fontSize: '25px',
		fontWeight: '300',
	},
	title: {
		color: '#666',
		fontSize: '48px',
		fontWeight: '300',
		lineHeight: '1.5em',
		margin: '0 0 10px 0',
		textAlign: 'center',
	},
});
