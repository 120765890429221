import {
	List,
	ListItem,
	withStyles,
} from '@material-ui/core';
import IconLink from '../IconLink';
import PropTypes from 'prop-types';
import React from 'react';
import style from './styles';

const menuList = [
	{ iconName: 'home', linkText: 'Home', pathToRedirect: '/home' },
	{ iconName: 'aboutUs', linkText: 'About Us', pathToRedirect: '/about' },
	{ iconName: 'portfolio', linkText: 'Portfolio', pathToRedirect: '/portfolio' },
	{ iconName: 'user', linkText: 'Login', pathToRedirect: '/login' },
];

const NavbarMenu = ({ ...props }) => {
	const { classes } = props;
	return (
		<List className={classes.list}>
			{menuList.map((menu, index) => (
				<ListItem className={classes.listItem} key={index}>
					<IconLink
						iconName={menu.iconName}
						linkText={menu.linkText}
						pathToRedirect={menu.pathToRedirect}
					/>
				</ListItem>
			))}
		</List>
	);
};

NavbarMenu.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(style)(NavbarMenu);
