export default (theme) => ({
	icon: {
		marginRight: '10px',
		paddingBottom: '3px',
	},
	navLink: {
		[theme.breakpoints.down('sm')]: {
			'& > span:first-child': {
				justifyContent: 'flex-start',
			},
			marginBottom: '8px',
			marginLeft: '15px',
			marginTop: '8px',
			textAlign: 'left',
			width: 'calc(100% - 30px)',
		},
		'&:hover': {
			color: '#D68B1B',
		},
		borderRadius: '3px',
		color: '#ffffff',
		display: 'inline-flex',
		fontSize: '18.5px',
		fontWeight: '300',
		margin: '0px',
		padding: '0.9375rem',
		position: 'relative',
		textDecoration: 'none !important',
	},
});
